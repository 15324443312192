import React, { useContext, useState } from 'react'
import { useLanguage } from '../../../LanguageProvider';
import countryCodes from '../../Signup/countryCodes.json'
import CustomPhoneSelect from '../../../components/CustomPhoneSelect/CustomPhoneSelect';
import { GeneralContext } from '../../../GeneralProvider';
import { useNavigate } from 'react-router-dom';
import MessageBox from '../../../components/MessageBox/MessageBox';
import '../ProfilePage.css'
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export default function ChangePassword() {
  const navigate = useNavigate();
  const { getTranslation } = useLanguage();
  const { handleUpdateUserPassword, handlePageChange } = useContext(GeneralContext);

  const sessionUser = JSON.parse(sessionStorage.getItem('user'));
  const localUser = JSON.parse(localStorage.getItem('user'));

  const user = localUser ? localUser : sessionUser;


  const [errors, setErrors] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const validateInputs = () => {
    const newErrors = [];

    // Password validation
    if (!/^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,})$/.test(password)) {
      newErrors.push('passwordError');
    }

    // Password Check validation
    if (password !== passwordRepeat) {
      newErrors.push('passwordMatchError');
    }

    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordRepeatVisibility = () => {
    setShowPasswordRepeat(!showPasswordRepeat);
  };


  const updateUserPassword = () => {
    setShowModal(false)
    if (!validateInputs()) return;


    handleUpdateUserPassword(user.id, password)
      .then((isSuccess) => {
        if (isSuccess) {
          handlePageChange('collections')
          localStorage.removeItem('username');
          localStorage.removeItem('password');
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('user')
          navigate('/');
        }
      })
  }

  return (
    <div className='main-page'>
      <div style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
        <div className='profile-card'>
          <p style={{ fontSize: '16px', marginBottom: '35px' }}>{getTranslation('changePassword')}</p>

          <div style={{ marginTop: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            <p style={{ width: '200px', fontSize: '14px' }}>{getTranslation('password')} :</p>
            <div style={{ display: 'flex', alignItems: 'center', width: '350px', position: 'relative' }}>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ width: '100%', paddingRight: '30px' }}
                placeholder={getTranslation('password')}
              />
              <span
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '10px',
                  cursor: 'pointer',
                  color: '#999',
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          <div style={{ marginTop: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            <p style={{ width: '200px', fontSize: '14px' }}>{getTranslation('passwordRepeat')} :</p>
            <div style={{ display: 'flex', alignItems: 'center', width: '350px', position: 'relative' }}>
              <input
                type={showPasswordRepeat ? 'text' : 'password'}
                id="passwordRepeat"
                name="passwordRepeat"
                value={passwordRepeat}
                onChange={(e) => setPasswordRepeat(e.target.value)}
                style={{ width: '100%', paddingRight: '30px' }}
                placeholder={getTranslation('passwordRepeat')}
              />
              <span
                onClick={togglePasswordRepeatVisibility}
                style={{
                  position: 'absolute',
                  right: '10px',
                  cursor: 'pointer',
                  color: '#999',
                }}
              >
                {showPasswordRepeat ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          {errors.length > 0 && (
            <div className='error-messages'>
              {errors.map((error, index) => (
                <p key={index} style={{ color: 'red', fontSize: '14px' }}>- {getTranslation(error)}</p>
              ))}
            </div>
          )}

          <button onClick={() => setShowModal(true)} style={{ marginTop: '45px', width: '550px' }}>{getTranslation('save')}</button>

        </div>

        <MessageBox show={showModal} title={getTranslation('areYouSure')} message={getTranslation('saveChangesConfirmation')} onConfirm={updateUserPassword} onClose={() => setShowModal(false)} />
      </div>
    </div>

  )
}
