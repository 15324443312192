import React, { useContext, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import { useLanguage } from '../../LanguageProvider';
import { GeneralContext } from '../../GeneralProvider';

export default function AddBoardModal({ isOpen, onClose, panelId }) {
    const { getTranslation } = useLanguage();
    const { handleAddBoard } = useContext(GeneralContext);

    const [boardTitle, setBoardTitle] = useState('');

    const handleInputChange = (e) => {
        setBoardTitle(e.target.value);
    };

    const handleAddBoardFunc = () => {
        let newBoard = {
            panelId: panelId,
            title: boardTitle
        }

        handleAddBoard(newBoard)
        setBoardTitle("")
        onClose();
    };

    const handleClose = () => {
        onClose()
        setBoardTitle("")
    }

    return (
        <Modal isOpen={isOpen} onClose={handleClose} position={'middle'}>
            <div>
                <h2>{getTranslation('addABoard')}</h2>
                <input
                    type="text"
                    value={boardTitle}
                    onChange={handleInputChange}
                    placeholder={getTranslation('enterBoardTitle')}
                    style={{ width: '100%', padding: '10px', marginBottom: '20px', border: '1px solid #ccc' }}
                />
                <div style={{ width: '100%', alignItems: 'center', justifyContent: 'flex-end', display: 'flex' }}>
                    <button onClick={handleAddBoardFunc}>
                        {getTranslation('addBoard')}
                    </button>
                </div>

            </div>
        </Modal>
    );
}
