import React, { useEffect, useState } from "react";
import TimeInput from "./TimeInput";
import { useLanguage } from "../../../LanguageProvider";
import { IoMdPlay } from "react-icons/io";
import "./TimerList.css";
import MessageBox from "../../../components/MessageBox/MessageBox";

export default function TimerList({
  timeLog,
  setTimeLog,
  handleDelete,
  handleUpdateLog,
  onPlay,
  currentKronos,
}) {
  const { getTranslation } = useLanguage();
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [tempTitle, setTempTitle] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedGroups, setExpandedGroups] = useState({});

  const updateTimeLogWithKronos = (currentKronos) => {
    if (currentKronos?.result) {
      const { logId, kronosTitle, startDateTime } = currentKronos.result;

      const updatedTimeLogs = timeLog.map((log) =>
        log.id === logId
          ? {
              ...log,
              title: kronosTitle,
              start: new Date(startDateTime * 1000),
              duration: Math.floor(Date.now() / 1000 - startDateTime),
            }
          : log
      );

      setTimeLog(updatedTimeLogs);
    }
  };

  useEffect(() => {
    if (currentKronos) {
      updateTimeLogWithKronos(currentKronos);
    }
  }, [currentKronos]);

  const handleFieldClick = (id, field, title = "") => {
    setEditingIndex(id);
    setEditingField(field);
    if (field === "title") setTempTitle(title);
  };

  const handleSaveTitle = (id, newTitle) => {
    const index = timeLog.findIndex((log) =>
      log.timeLogs.some((timeLog) => timeLog.logId === id)
    );
    if (index === -1) return;
    const updatedLog = {
      ...timeLog[index],
      title: newTitle,
    };
    setTimeLog((prevLogs) => [
      ...prevLogs.slice(0, index),
      updatedLog,
      ...prevLogs.slice(index + 1),
    ]);
    setEditingIndex(null);
    setEditingField(null);
  };

  const handleSaveStart = (id, newHours, newMinutes) => {
    const index = timeLog.findIndex((log) =>
      log.timeLogs.some((timeLog) => timeLog.logId === id)
    );
    if (index === -1) return;

    const log = timeLog[index];
    const timeLogIndex = log.timeLogs.findIndex((tl) => tl.logId === id);
    if (timeLogIndex === -1) return;

    const currentTimestamp = parseInt(
      log.timeLogs[timeLogIndex].startDateTime,
      10
    );
    const updatedStartDateTime = new Date(currentTimestamp * 1000);
    updatedStartDateTime.setHours(newHours);
    updatedStartDateTime.setMinutes(newMinutes);

    const updatedTimeLog = {
      ...log.timeLogs[timeLogIndex],
      startDateTime: Math.floor(
        updatedStartDateTime.getTime() / 1000
      ).toString(),
      duration: log.timeLogs[timeLogIndex].endDateTime
        ? Math.floor(
            parseInt(log.timeLogs[timeLogIndex].endDateTime, 10) -
              Math.floor(updatedStartDateTime.getTime() / 1000)
          )
        : 0,
    };

    const updatedLog = {
      ...log,
      timeLogs: [
        ...log.timeLogs.slice(0, timeLogIndex),
        updatedTimeLog,
        ...log.timeLogs.slice(timeLogIndex + 1),
      ],
    };

    setTimeLog((prevLogs) => [
      ...prevLogs.slice(0, index),
      updatedLog,
      ...prevLogs.slice(index + 1),
    ]);

    setEditingIndex(null);
    setEditingField(null);
  };

  const handleSaveEnd = (id, newHours, newMinutes) => {
    const index = timeLog.findIndex((log) =>
      log.timeLogs.some((timeLog) => timeLog.logId === id)
    );
    if (index === -1) return;

    const log = timeLog[index];
    const timeLogIndex = log.timeLogs.findIndex((tl) => tl.logId === id);
    if (timeLogIndex === -1) return;

    const updatedEndDateTime = new Date(
      log.timeLogs[timeLogIndex].endDateTime.replace(" ", "T")
    );
    updatedEndDateTime.setHours(newHours);
    updatedEndDateTime.setMinutes(newMinutes);

    const updatedTimeLog = {
      ...log.timeLogs[timeLogIndex],
      endDateTime: updatedEndDateTime.toISOString(),
      duration: log.timeLogs[timeLogIndex].startDateTime
        ? Math.floor(
            (updatedEndDateTime -
              new Date(log.timeLogs[timeLogIndex].startDateTime)) /
              1000
          )
        : 0,
    };

    const updatedLog = {
      ...log,
      timeLogs: [
        ...log.timeLogs.slice(0, timeLogIndex),
        updatedTimeLog,
        ...log.timeLogs.slice(timeLogIndex + 1),
      ],
    };

    setTimeLog((prevLogs) => [
      ...prevLogs.slice(0, index),
      updatedLog,
      ...prevLogs.slice(index + 1),
    ]);

    setEditingIndex(null);
    setEditingField(null);
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteId(id);
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    if (deleteId) {
      handleDelete(deleteId);
    }
    setIsModalOpen(false);
    setDeleteId(null);
  };

  const formatDateTime = (timestamp) => {
    if (!timestamp) return null;
    const dateTime = new Date(parseInt(timestamp, 10) * 1000);
    return dateTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const formatDuration = (durationInSeconds) => {
    if (!durationInSeconds) return "00:00:00";
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const categorizedData = timeLog?.map((log) => ({
    ...log,
    timeLogs: log.timeLogs?.map((timeLog) => ({
      ...timeLog,
      startFormatted: timeLog.startDateTime
        ? formatDateTime(timeLog.startDateTime)
        : getTranslation("noStartTime"),
      endFormatted: timeLog.endDateTime
        ? formatDateTime(timeLog.endDateTime)
        : "?",
    })),
  }));

  return (
    <>
      {categorizedData?.length > 0 && (
        <ul className="time-log">
          {categorizedData?.map((log) =>
            log?.timeLogs?.map((timeLog) => (
              <li key={timeLog.logId} className="time-log-item card">
                <span
                  className="title"
                  onClick={() =>
                    handleFieldClick(timeLog.logId, "title", log.title)
                  }
                >
                  {editingIndex === timeLog.logId &&
                  editingField === "title" ? (
                    <input
                      type="text"
                      value={tempTitle}
                      onChange={(e) => setTempTitle(e.target.value)}
                      onBlur={() => handleSaveTitle(timeLog.logId, tempTitle)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSaveTitle(timeLog.logId, tempTitle);
                        }
                      }}
                      autoFocus
                    />
                  ) : (
                    log.title
                  )}
                </span>
                <div className="right-items">
                  <IoMdPlay
                    size={18}
                    style={{
                      cursor: "pointer",
                      marginRight: "8px",
                      color: "green",
                    }}
                    onClick={() => onPlay(timeLog)}
                  />
                  <span className="times">
                    {editingIndex === timeLog.logId &&
                    editingField === "start" ? (
                      <>
                        <TimeInput
                          value={
                            timeLog.startDateTime
                              ? new Date(timeLog.startDateTime).getHours()
                              : ""
                          }
                          onSave={(newHours) =>
                            handleSaveStart(
                              timeLog.logId,
                              newHours,
                              timeLog.startDateTime
                                ? new Date(timeLog.startDateTime).getMinutes()
                                : 0
                            )
                          }
                          max={23}
                          isHour={true}
                        />
                        :
                        <TimeInput
                          value={
                            timeLog.startDateTime
                              ? new Date(timeLog.startDateTime).getMinutes()
                              : ""
                          }
                          onSave={(newMinutes) =>
                            handleSaveStart(
                              timeLog.logId,
                              timeLog.startDateTime
                                ? new Date(timeLog.startDateTime).getHours()
                                : 0,
                              newMinutes
                            )
                          }
                          max={59}
                        />
                      </>
                    ) : (
                      <span
                        onClick={() => handleFieldClick(timeLog.logId, "start")}
                      >
                        {timeLog.startFormatted}
                      </span>
                    )}
                    <p className="line">-</p>
                    {editingIndex === timeLog.logId &&
                    editingField === "end" ? (
                      <>
                        <TimeInput
                          value={
                            timeLog.endDateTime
                              ? new Date(timeLog.endDateTime).getHours()
                              : ""
                          }
                          onSave={(newHours) =>
                            handleSaveEnd(
                              timeLog.logId,
                              newHours,
                              timeLog.endDateTime
                                ? new Date(timeLog.endDateTime).getMinutes()
                                : 0
                            )
                          }
                          max={23}
                          isHour={true}
                        />
                        :
                        <TimeInput
                          value={
                            timeLog.endDateTime
                              ? new Date(timeLog.endDateTime).getMinutes()
                              : ""
                          }
                          onSave={(newMinutes) =>
                            handleSaveEnd(
                              timeLog.logId,
                              timeLog.endDateTime
                                ? new Date(timeLog.endDateTime).getHours()
                                : 0,
                              newMinutes
                            )
                          }
                          max={59}
                        />
                      </>
                    ) : (
                      <span
                        onClick={() => handleFieldClick(timeLog.logId, "end")}
                      >
                        {timeLog.endFormatted}
                      </span>
                    )}
                  </span>
                  <span className="duration">
                    {formatDuration(timeLog.duration || 0)}
                  </span>
                  <button
                    className="button-cancel"
                    onClick={() => handleOpenDeleteModal(timeLog.logId)}
                  >
                    {getTranslation("delete")}
                  </button>
                </div>
              </li>
            ))
          )}
        </ul>
      )}
      <MessageBox
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={confirmDelete}
        title={getTranslation("doYouWantToDelete")}
      />
    </>
  );
}
