import React, { useEffect, useRef, useState } from "react";
import './SignUpPhoneSelect.css';

export default function SignUpPhoneSelect({ options, value, onChange, themeSensitive }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedFlag, setSelectedFlag] = useState("https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tr.svg");

    const selectRef = useRef(null);

    const handleOptionClick = (val, flag) => {
        onChange(val);
        setIsOpen(false);
        setSelectedFlag(flag)
    };

    const handleOutsideClick = (e) => {
        if (selectRef.current && !selectRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    if (themeSensitive) {
        return (
            <div className="custom-phone-select" ref={selectRef}>
                <div
                    onClick={() => setIsOpen(!isOpen)}
                    className="select-trigger"
                >
                    <img
                        src={selectedFlag}
                        alt={""}
                        className="flag-image"
                    />
                    {value}
                </div>
                {isOpen && (
                    <div className="options-container">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                onClick={() => handleOptionClick(option.dialCodes, option.image)}
                                className="option-item"
                            >
                                <img
                                    src={option.image}
                                    alt={option.name}
                                    className="flag-image"
                                />
                                {option.dialCodes}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
    else {
        return (
            <div className="custom-phone-select"  ref={selectRef}>
                <div
                    onClick={() => setIsOpen(!isOpen)}
                    className="select-trigger"
                >
                    <img
                        src={selectedFlag}
                        alt={""}
                        className="flag-image"
                    />
                    {value}
                </div>
                {isOpen && (
                    <div className="options-container">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                onClick={() => handleOptionClick(option.dialCodes, option.image)}
                                className="option-item"
                            >
                                <img
                                    src={option.image}
                                    alt={option.name}
                                    className="flag-image"
                                />
                                {option.dialCodes}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

};