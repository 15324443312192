import React, { useState, useEffect, useContext, useRef } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useLanguage } from '../../LanguageProvider';
import { useNavigate } from 'react-router-dom';
import Services from '../../services/Services';
import { GeneralContext } from '../../GeneralProvider';
import { useToaster } from '../../ToasterProvider';
import countryCodes from './countryCodes.json'
import SignUpPhoneSelect from './SignUpPhoneSelect';

import * as Components from './Components';

function AuthPage() {
  const canvasRef = useRef(null);
  const navigate = useNavigate();
  const { getTranslation } = useLanguage();
  const { toastError, toastSuccess } = useToaster();
  const [signIn, toggle] = useState(true);

  //Login
  const { handleTokenUpdate, handleSelectProject } = useContext(GeneralContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState("");

  //Signup
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [signUpUsername, setSignUpUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("+90");
  const [signUpPassword, setSignUpPassword] = useState("");
  const [showSignUpPassword, setShowSignUpPassword] = useState(false);
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [errors, setErrors] = useState([]);

  //Signup Functions

  const toggleSignUpPasswordVisibility = () => {
    setShowSignUpPassword(!showSignUpPassword);
  };

  const togglePasswordRepeatVisibility = () => {
    setShowPasswordRepeat(!showPasswordRepeat);
  };

  const validateInputs = () => {
    const newErrors = [];

    // Username validation
    if (signUpUsername.length < 3 || signUpUsername.length > 20 || /[^\w]/.test(signUpUsername)) {
      newErrors.push('usernameError');
    }

    // Name validation
    if (name.length < 2 || name.length > 50 || /[^a-zA-Z' ]/g.test(name)) {
      newErrors.push('nameError');
    }

    // Lastname validation
    if (lastName.length < 1 || lastName.length > 50 || /[^a-zA-Z']/g.test(lastName)) {
      newErrors.push('lastnameError');
    }

    // Password validation
    if (!/^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,})$/.test(signUpPassword)) {
      newErrors.push('passwordError');
    }

    // Password Check validation
    if (signUpPassword !== passwordRepeat) {
      newErrors.push('passwordMatchError');
    }

    // Phone validation
    if (!/^\d{10}$/.test(phone)) {
      newErrors.push('phoneError');
    }

    // Email validation
    if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      newErrors.push('emailError');
    }

    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const userRegister = () => {
    if (!validateInputs()) return;

    Services.GetToken()
      .then((res) => {
        if (res.data.status) {
          Services.UserRegister(res.data.result.token, name, lastName, signUpUsername, signUpPassword, phone, countryCode, email)
            .then((res) => {
              if (res.data.status) {
                toastSuccess(getTranslation('successMessage'))
                navigate('/');
              }
              else {
                const newErrors = [];

                if (res.data.message.includes("username already exists")) {
                  newErrors.push("userNameExist")
                }
                if (res.data.message.includes("email already exists")) {
                  newErrors.push("emailExist")
                }
                if (res.data.message.includes('phone already exists')) {
                  newErrors.push('phoneExist')
                }
                setErrors(newErrors);
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));


  };
  //Login Functions
  const handleLogin = () => {
    Services.UserLogin(token, username, password)
      .then((res) => {
        if (res.data.status) {
          if (rememberMe) {
            localStorage.setItem('username', username);
            localStorage.setItem('password', password);
            localStorage.setItem('user', JSON.stringify(res.data.result));
            localStorage.setItem('token', res.data.result.token);

          } else {
            sessionStorage.setItem('user', JSON.stringify(res.data.result));
            sessionStorage.setItem('token', res.data.result.token);
          }
          handleTokenUpdate(res.data.result.token)
          if (res.data.result.selectedProject) {
            handleSelectProject(res.data.result.selectedProject, res.data.result.token)
          }
          navigate('/home');
        }
        else {
          toastError(getTranslation('invalidCredentials'))
        }
      })
      .catch((err) => console.log(err));
  };

  const handleAutoLogin = (token, username, password) => {
    Services.UserLogin(token, username, password)
      .then((res) => {
        if (res.data.status) {
          localStorage.setItem('user', JSON.stringify(res.data.result));
          handleTokenUpdate(res.data.result.token)
          if (res.data.result.selectedProject) {
            handleSelectProject(res.data.result.selectedProject, res.data.result.token)
          }
          navigate('/home');
        }
        else {
          toastError(getTranslation('invalidCredentials'))
        }
      })
      .catch((err) => console.log(err));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getToken = () => {
    Services.GetToken()
      .then((res) => {
        if (res.data.status) {
          setToken(res.data.result.token);
          const loginUserName = localStorage.getItem('username')
          const loginPassword = localStorage.getItem('password')
          const loginToken = res.data.result.token
          if (loginUserName && loginPassword && loginToken) {
            handleAutoLogin(loginToken, loginUserName, loginPassword)
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getToken()
  }, []);

  const loginUserName = localStorage.getItem('username')
  const loginPassword = localStorage.getItem('password')

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const glitchEffect = () => {
      const width = canvas.width;
      const height = canvas.height;

      for (let i = 0; i < 10; i++) {
        const x = Math.random() * width;
        const lineWidth = Math.random() * 5 + 1;
        const lineHeight = height;

        ctx.fillStyle = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.3)`;
        ctx.fillRect(x, 0, lineWidth, lineHeight);
      }

      setTimeout(() => {
        ctx.clearRect(0, 0, width, height);
      }, 100);
    };

    const animate = () => {
      if (Math.random() > 0.7) {
        glitchEffect();
      }
      requestAnimationFrame(animate);
    };

    animate();

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (loginUserName && loginPassword) {
    <></>
  }
  else {
    return (
      <div style={{ width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <canvas ref={canvasRef} className="background-canvas"></canvas>
        <Components.Container>
          <Components.SignUpContainer signinIn={signIn}>
            <div style={{ backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '0 50px', height: '100%', textAlign: 'center' }}>
              <Components.Input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={getTranslation('name')}
              />

              <Components.Input
                type="text"
                id="lastname"
                name="lastname"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={getTranslation('lastname')}
              />

              <Components.Input
                type="text"
                id="signUpUsername"
                name="signUpUsername"
                value={signUpUsername}
                onChange={(e) => setSignUpUsername(e.target.value)}
                placeholder={getTranslation('username')}
              />

              <Components.Input
                type="text"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={getTranslation('email')}
              />

              <SignUpPhoneSelect
                options={countryCodes}
                value={countryCode}
                onChange={setCountryCode}
              />
              <Components.Input
                id="phone"
                name="phone"
                value={phone}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) { // Sadece rakamları kabul et
                    setPhone(value);
                  }
                }}
                placeholder={getTranslation('phone')}
                maxLength={10}
              />

              <div style={{ position: 'relative', width: '100%' }}>
                <Components.Input
                  type={showSignUpPassword ? 'text' : 'password'}
                  id="signUpPassword"
                  name="signUpPassword"
                  value={signUpPassword}
                  onChange={(e) => setSignUpPassword(e.target.value)}
                  placeholder={getTranslation('password')}
                />
                <span
                  onClick={toggleSignUpPasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)', // Ortalamak için
                    cursor: 'pointer',
                    color: '#999',
                  }}
                >
                  {showSignUpPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>

              <div style={{ position: 'relative', width: '100%' }}>
                <Components.Input
                  type={showPasswordRepeat ? 'text' : 'password'}
                  id="passwordRepeat"
                  name="passwordRepeat"
                  value={passwordRepeat}
                  onChange={(e) => setPasswordRepeat(e.target.value)}
                  style={{ width: '100%', paddingRight: '30px' }}
                  placeholder={getTranslation('passwordRepeat')}
                />
                <span
                  onClick={togglePasswordRepeatVisibility}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)', // Ortalamak için
                    cursor: 'pointer',
                    color: '#999',
                  }}
                >
                  {showPasswordRepeat ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>

              <Components.Button>{getTranslation('signup')}</Components.Button>
            </div>
          </Components.SignUpContainer>

          <Components.SignInContainer signinIn={signIn}>
            <div style={{ backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '0 50px', height: '100%', textAlign: 'center' }}>
              <Components.Input
                type="text"
                id="username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={handleKeyDown}  // Enter tuşu için
                placeholder={getTranslation('username') + " / " + getTranslation('email')} />

              <div style={{ position: 'relative', width: '100%' }}>
                <Components.Input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyDown} // Enter tuşu için
                  style={{
                    width: '100%',
                    paddingRight: '40px', // Boşluk bırak, yoksa ikon üzerine gelir
                  }}
                  placeholder={getTranslation('password')}
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)', // Ortalamak için
                    cursor: 'pointer',
                    color: '#999',
                  }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <div style={{ flexDirection: 'column', display: 'flex', width: '100%', marginBottom: '15px' }}>
                <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                  <label style={{ fontSize: '12px', fontWeight: '400', display: 'flex', alignItems: 'center', color: 'black' }}>
                    <input
                      type="checkbox"
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                      style={{ backgroundColor: '#001a00',border:'2px solid #00ff00' }}
                    />
                    {getTranslation('rememberMe')}
                  </label>

                </div>
              </div>
              <Components.Anchor href='#'>Forgot your password?</Components.Anchor>
              <Components.Button onClick={handleLogin} >{getTranslation('login')}</Components.Button>
            </div>
          </Components.SignInContainer>

          <Components.OverlayContainer signinIn={signIn}>
            <Components.Overlay signinIn={signIn}>

              <Components.LeftOverlayPanel signinIn={signIn}>
                <Components.Title>Welcome Back!</Components.Title>
                <Components.Paragraph>
                  To keep connected with us please login with your personal info
                </Components.Paragraph>
                <Components.GhostButton onClick={() => toggle(true)}>
                  {getTranslation('login')}
                </Components.GhostButton>
              </Components.LeftOverlayPanel>

              <Components.RightOverlayPanel signinIn={signIn}>
                <Components.Title>Hello, Friend!</Components.Title>
                <Components.Paragraph>
                  Enter Your personal details and start journey with us
                </Components.Paragraph>
                <Components.GhostButton onClick={() => toggle(false)}>
                  {getTranslation('signup')}
                </Components.GhostButton>
              </Components.RightOverlayPanel>

            </Components.Overlay>
          </Components.OverlayContainer>

        </Components.Container>
      </div>
    )
  }

}

export default AuthPage;