import React, { useState } from "react";
import "./TimeInput.css";

export default function TimeInput({ value, onSave, max, isHour = false }) {
  const [tempValue, setTempValue] = useState(value.toString().padStart(2, "0"));
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    let inputValue = e.target.value;

    // Eğer input boş bırakıldıysa veya geçersizse varsayılan olarak bırak
    if (inputValue === "" || isNaN(inputValue)) {
      setTempValue("");
      return;
    }

    // Eğer iki karakterden fazlaysa, fazla karakteri at
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2);
    }

    // Eğer girilen değer maksimum değeri geçiyorsa sınırla
    if (parseInt(inputValue, 10) > max) {
      setTempValue(max.toString().padStart(2, "0"));
    } else {
      setTempValue(inputValue); // Tek karakter veya iki karakter de olsa kaydet
    }
  };

  const handleBlur = () => {
    let updatedValue = tempValue;

    // Eğer boş ya da geçersizse "00" olarak ayarla
    if (tempValue === "" || isNaN(tempValue) || parseInt(tempValue, 10) > max) {
      updatedValue = "00";
    } else if (tempValue.length === 1) {
      updatedValue = "0" + tempValue;
    }

    setTempValue(updatedValue);
    setIsEditing(false);
    onSave(parseInt(updatedValue, 10));
  };

  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  return (
    <input
      type="text"
      value={tempValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      maxLength={2}
      className={`time-input ${isEditing ? "editing" : ""}`}
    />
  );
}
