import React, { useState, useRef, useEffect } from "react";
import AddManualModal from "./AddManualModal";
import { useLanguage } from "../../../LanguageProvider";
import "./Timer.css";
import TimeInput from "./TimeInput";
import { useToaster } from "../../../ToasterProvider";
import CircularProgress from "../../../components/CircularProgress/CircularProgress";
import MessageBox from "../../../components/MessageBox/MessageBox";
import MultipleMessageBox from "../../../components/MultipleMessageBox/MultipleMessageBox";

export default function Timer({
  onAddLog,
  timeLog,
  setTimeLog,
  currentLog,
  createAndStart,
  stop,
  refreshKronosData,
  handleCreateManualKronos,
  handleGetCurrentKronos,
  handleSavePreviousAndStart,
  handleCancelPreviousAndStart,
  handleDiscardAndResetTimer,
}) {
  const { getTranslation } = useLanguage();
  const { toastWarning, toastError } = useToaster();
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [title, setTitle] = useState("");
  const [currentId, setCurrentId] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [isManualModalOpen, setIsManualModalOpen] = useState(false);
  const [showStartTimeInput, setShowStartTimeInput] = useState(false);
  const [logId, setLogId] = useState(null);
  const [showActiveKronosModal, setShowActiveKronosModal] = useState(false);
  const [showCancelMessageBox, setShowCancelMessageBox] = useState(false);
  const [currentKronosData, setCurrentKronosData] = useState(null);
  const startTimeRef = useRef(null);
  const sessionUser = JSON.parse(sessionStorage.getItem("user"));
  const localUser = JSON.parse(localStorage.getItem("user"));
  const userId = sessionUser ? sessionUser?.id : localUser?.id;

  useEffect(() => {
    if (currentLog) {
      if (isRunning) {
        stopTimer(); // Mevcut çalışan timer varsa durdurup kaydediyoruz
      }
      console.log("currentLog", currentLog);
      setLogId(currentLog.logId);
      startNewTimer(currentLog); // Yeni log ile timer başlatıyoruz
    }
  }, [currentLog]);

  // useEffect(() => {
  //   if (isRunning && title) {
  //     document.title = `${formatTime(time)} - ${title}`;
  //   } else {
  //     document.title = defaultTitle;
  //   }
  //   return () => {
  //     document.title = defaultTitle;
  //   };
  // }, [isRunning, time, title]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        startTimeRef.current &&
        !startTimeRef.current.contains(event.target)
      ) {
        setShowStartTimeInput(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [startTimeRef]);

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const startNewTimer = (log) => {
    setTitle(log.title);
    setGroupId(log.groupId || Math.random().toString(36).substring(2, 11));
    setCurrentId(log.id);

    if (log.start) {
      const logStartTime = new Date(log.start);
      const now = new Date();
      const elapsedTime = Math.floor((now - logStartTime) / 1000);

      setStartTime(logStartTime);
      setTime(elapsedTime);
      setIsRunning(true);

      // Timer'ı otomatik çalıştır
      const interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
      setIntervalId(interval);
    } else {
      startTimer();
    }
  };

  const startTimer = () => {
    const newStartTime = new Date();
    setStartTime(newStartTime);
    setIntervalId(setInterval(() => setTime((prevTime) => prevTime + 1), 1000));
    setIsRunning(true);
  };

  const stopTimer = () => {
    clearInterval(intervalId);
    const endTime = new Date();
    const newLog = {
      title,
      start: startTime,
      duration: time,
      end: endTime,
      id: Math.random().toString(36).substring(2, 11),
      groupId: groupId || Math.random().toString(36).substring(2, 11),
    };

    const updatedLogs = timeLog.map((log) => {
      if (log.id === currentId || log.groupId === groupId) {
        return { ...log, groupId: newLog.groupId };
      }
      return log;
    });

    setTimeLog([...updatedLogs, newLog]);
    resetTimer();
  };

  const resetTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    setTitle("");
    setGroupId(null);
    setCurrentId(null);
    setIsCancelLoading(false);
    setIsRunning(false);
    setTime(0);
  };

  const handleStartStop = async () => {
    if (isLoading) return;
    setIsLoading(true);

    if (!title?.trim()) {
      toastWarning(getTranslation("pleaseFillInTheTitle"));
      setIsLoading(false);
      return;
    }

    if (isRunning) {
      try {
        console.log("stop: ", logId);
        const result = await stop(logId);
        console.log("Timer --> Kronos Stop'tan dönen data: ", result);

        if (result?.status) {
          stopTimer();
          await refreshKronosData(); // Stop işleminden sonra veriyi yenile
        } else {
          toastError(getTranslation("somethingWentWrong"));
        }
      } catch (error) {
        toastError(getTranslation("somethingWentWrong"));
        console.error("Kronos Stop Error: ", error);
      } finally {
        setIsLoading(false);
      }
      return; // Stop işleminden sonra geriye döneriz
    }

    try {
      const currentKronos = await handleGetCurrentKronos();
      if (currentKronos.status) {
        // Active Kronos bulundu
        setCurrentKronosData(currentKronos.result); // Active Kronos bilgilerini sakla
        setShowActiveKronosModal(true); // Modal göster
        setIsLoading(false);
        return;
      }

      // Eğer aktif Kronos yoksa yeni bir timer başlat
      const result = await createAndStart({
        title,
        members: [{ id: userId, permission: "write" }],
      });
      console.log("Timer --> Kronos'tan dönen data: ", result);

      if (result.status) {
        setLogId(result.result.logId);
        startTimer();
      } else {
        toastError(getTranslation("somethingWentWrong"));
      }
    } catch (error) {
      toastError(getTranslation("somethingWentWrong"));
      console.error("Kronos Error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartTimeChange = (hours, minutes) => {
    if (startTime) {
      const updatedStartTime = new Date(startTime);
      updatedStartTime.setHours(hours);
      updatedStartTime.setMinutes(minutes);
      setStartTime(updatedStartTime);

      const newDuration = Math.floor((new Date() - updatedStartTime) / 1000);
      setTime(newDuration);
    }
  };

  const handleSaveStartTime = () => {
    setShowStartTimeInput(false);
    if (startTime) {
      const newDuration = Math.floor((new Date() - startTime) / 1000);
      setTime(newDuration);
    }
  };

  const handleConfirmDiscard = async () => {
    setIsCancelLoading(true);
    const isDiscarded = await handleDiscardAndResetTimer(); // Kronos'tan discard işlemini yap
    console.log("handleConfirmDiscard response: ", isDiscarded);
    if (isDiscarded) {
      resetTimer(); // Eğer başarılıysa Timer'ı sıfırla
    } else setIsCancelLoading(false);
    setShowCancelMessageBox(false); // Modal'ı kapat
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="card timer-container">
        <input
          type="text"
          placeholder={getTranslation("whatAreYouWorkingOn")}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <span
          className={`timer-time ${isRunning ? "pointer" : ""}`}
          onClick={() =>
            isRunning ? setShowStartTimeInput(!showStartTimeInput) : null
          }
        >
          {formatTime(time)}
        </span>

        <button
          className={`start-button ${isRunning ? "stop-button" : ""}`}
          onClick={handleStartStop}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress style={{ width: "10px", height: "10px" }} />
          ) : isRunning ? (
            getTranslation("stop")
          ) : (
            getTranslation("start")
          )}
        </button>

        <button
          className="add-manual-button"
          onClick={() => setIsManualModalOpen(true)}
        >
          {getTranslation("addManual")}
        </button>

        <button
          className={`discard-button`}
          onClick={() => setShowCancelMessageBox(true)}
          disabled={isCancelLoading || !isRunning}
        >
          {isCancelLoading ? (
            <CircularProgress style={{ width: "10px", height: "10px" }} />
          ) : (
            getTranslation("cancel")
          )}
        </button>
      </div>

      {showStartTimeInput && (
        <div ref={startTimeRef} className="start-time-input-popup">
          <span
            className="close-x-button"
            onClick={() => setShowStartTimeInput(false)}
          >
            ×
          </span>
          <label>{getTranslation("startTime")}:</label>
          <div>
            <TimeInput
              value={startTime ? startTime.getHours() : 0}
              onSave={(newHours) =>
                handleStartTimeChange(
                  newHours,
                  startTime ? startTime.getMinutes() : 0
                )
              }
              max={23}
              isHour={true}
            />
            <span style={{ paddingInline: "5px" }}>:</span>
            <TimeInput
              value={startTime ? startTime.getMinutes() : 0}
              onSave={(newMinutes) =>
                handleStartTimeChange(
                  startTime ? startTime.getHours() : 0,
                  newMinutes
                )
              }
              max={59}
            />
          </div>

          <button onClick={handleSaveStartTime} className="save-button">
            {getTranslation("save")}
          </button>
        </div>
      )}

      <MultipleMessageBox
        show={showActiveKronosModal}
        onClose={() => setShowActiveKronosModal(false)}
        title={getTranslation("activeKronosIsFound")}
        message={
          currentKronosData
            ? getTranslation("activeKronosMessage", {
                projectTitle: currentKronosData.projectTitle,
                kronosTitle: currentKronosData.kronosTitle,
              })
            : ""
        }
        actions={[
          {
            title: getTranslation("savePreviousAndStart"),
            type: "confirm",
            callback: () => {
              handleSavePreviousAndStart(currentKronosData, title);
              setShowActiveKronosModal(false);
            },
          },
          {
            title: getTranslation("cancelPreviousAndStart"),
            type: "warning",
            callback: () => {
              handleCancelPreviousAndStart(currentKronosData, title);
              setShowActiveKronosModal(false);
            },
          },
        ]}
      />

      <AddManualModal
        isOpen={isManualModalOpen}
        onClose={() => setIsManualModalOpen(false)}
        onAdd={handleCreateManualKronos}
      />

      <MessageBox
        show={showCancelMessageBox}
        onClose={() => setShowCancelMessageBox(false)}
        title={getTranslation("cancel")}
        message={getTranslation("areYouSure")}
        onConfirm={handleConfirmDiscard}
      />
    </div>
  );
}
