// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-circle{
    border-radius: 50px;
    background-color: var(--task-card-bg-color);
    height: 50px;
    width: 50px;
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/Avatar/Avatar.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,2CAA2C;IAC3C,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB","sourcesContent":[".avatar-circle{\r\n    border-radius: 50px;\r\n    background-color: var(--task-card-bg-color);\r\n    height: 50px;\r\n    width: 50px;\r\n    margin-bottom: 15px;\r\n    align-items: center;\r\n    justify-content: center;\r\n    display: flex;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
