import React, { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import { useLanguage } from "../../../LanguageProvider";
import "./AddManualModal.css";
import { useToaster } from "../../../ToasterProvider";

export default function AddManualModal({ isOpen, onClose, onAdd }) {
  const { getTranslation } = useLanguage();
  const { toastWarning } = useToaster();

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const getCurrentTime = () => {
    const today = new Date();
    return today.toTimeString().split(":").slice(0, 2).join(":");
  };

  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState(getTodayDate());
  const [startTime, setStartTime] = useState(getCurrentTime());
  const [endDate, setEndDate] = useState(getTodayDate());
  const [endTime, setEndTime] = useState(getCurrentTime());

  const handleAdd = () => {
    if (!title?.trim()) {
      toastWarning(getTranslation("pleaseFillInTheTitle"));
      return;
    }
    const startDateTime = `${startDate}T${startTime}`;
    const endDateTime = `${endDate}T${endTime}`;
    onAdd(title, startDateTime, endDateTime);
    console.log({ title, startDate: startDateTime, endDate: endDateTime });
    onClose();
    resetFields();
  };

  const resetFields = () => {
    setTitle("");
    setStartDate(getTodayDate());
    setStartTime(getCurrentTime());
    setEndDate(getTodayDate());
    setEndTime(getCurrentTime());
  };

  const handleStartDateChange = (value) => {
    setStartDate(value);
    if (new Date(value) > new Date(endDate)) {
      setEndDate(value);
    }
  };

  const handleStartTimeChange = (value) => {
    setStartTime(value);
    if (startDate === endDate && value > endTime) {
      setEndTime(value);
    }
  };

  const handleEndDateChange = (value) => {
    setEndDate(value);
    if (new Date(value) < new Date(startDate)) {
      setStartDate(value);
    }
  };

  const handleEndTimeChange = (value) => {
    setEndTime(value);
    if (startDate === endDate && value < startTime) {
      setStartTime(value);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} position={"middle"}>
      <div
        style={{
          textAlign: "center",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        {getTranslation("addManual")}
      </div>

      <div className="card" style={{ marginTop: "15px" }}>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={getTranslation("whatAreYouWorkingOn")}
        />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            flexDirection: "column",
            marginTop: "15px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <label style={{ marginBottom: "5px" }}>
              {getTranslation("startTime")}
            </label>
            <div style={{ display: "flex", gap: "10px" }}>
              <input
                type="date"
                value={startDate}
                onChange={(e) => handleStartDateChange(e.target.value)}
                max={endDate}
                style={{
                  width: "50%",
                  padding: "5px",
                  border: "1px solid #a5a5a5",
                  borderRadius: "5px",
                }}
              />
              <input
                type="time"
                value={startTime}
                onChange={(e) => handleStartTimeChange(e.target.value)}
                max={startDate === endDate ? endTime : undefined}
                style={{
                  width: "50%",
                  padding: "5px",
                  border: "1px solid #a5a5a5",
                  borderRadius: "5px",
                }}
              />
            </div>
          </div>

          <div
            style={{
              height: "0.5px",
              width: "100%",
              backgroundColor: "#fff",
              margin: "30px 0",
            }}
          ></div>

          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <label style={{ marginBottom: "5px" }}>
              {getTranslation("endTime")}
            </label>
            <div style={{ display: "flex", gap: "10px" }}>
              <input
                type="date"
                value={endDate}
                onChange={(e) => handleEndDateChange(e.target.value)}
                min={startDate}
                style={{
                  width: "50%",
                  padding: "5px",
                  border: "1px solid #a5a5a5",
                  borderRadius: "5px",
                }}
              />
              <input
                type="time"
                value={endTime}
                onChange={(e) => handleEndTimeChange(e.target.value)}
                min={startDate === endDate ? startTime : undefined}
                style={{
                  width: "50%",
                  padding: "5px",
                  border: "1px solid #a5a5a5",
                  borderRadius: "5px",
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
            marginTop: "20px",
          }}
        >
          <button
            onClick={handleAdd}
            style={{
              padding: "8px 16px",
              backgroundColor: "#d8681d",
              color: "white",
              border: "none",
              borderRadius: "5px",
              width: "80px",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            {getTranslation("add")}
          </button>
          <button
            onClick={onClose}
            style={{
              padding: "8px 16px",
              backgroundColor: "#555",
              color: "white",
              border: "none",
              borderRadius: "5px",
              width: "80px",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            {getTranslation("close")}
          </button>
        </div>
      </div>
    </Modal>
  );
}
