import React, { useContext } from 'react'
import { GeneralContext } from '../../GeneralProvider';
import ProfileInfo from './Views/ProfileInfo';
import ChangePassword from './Views/ChangePassword';
import SuspendAccount from './Views/SuspendAccount';

export default function ProfilePage() {
  const { profilePageSection } = useContext(GeneralContext);

  const renderPage = () => {
    switch (profilePageSection) {
      case 'profileInfo':
        return <ProfileInfo />;
      case 'changePassword':
        return <ChangePassword />;
      // case 'suspendAccount':
      //   return <SuspendAccount />;
      default:
        return null;
    }
  };


  return (
    <>{renderPage()}</>
  )
}
