import React, { useState, useEffect } from "react";

const CountdownToJan2025 = () => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    function calculateTimeLeft() {
        const targetDate = new Date("2025-01-02T00:00:00");
        const now = new Date();
        const difference = targetDate - now;

        if (difference <= 0) {
            return null; // Geri sayım bitti.
        }

        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / (1000 * 60)) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer); // Temizleme işlemi.
    }, []);

    if (!timeLeft) {
        return <div>🎉 Geri sayım tamamlandı! 🎉</div>;
    }

    return (
        <div style={{ position: 'absolute', left: '50%' }}>
            <p style={{ fontSize: '18px',textAlign:'center' }}>
               {timeLeft.days}:{timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
            </p>
        </div>
    );
};

export default CountdownToJan2025;
