// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-messages {
    margin-top: 10px; /* Üstte biraz boşluk bırak */
    padding: 10px; /* Mesajların etrafında biraz iç boşluk */
    background-color: #f3bbc0; /* Açık kırmızı arka plan */
    border: 1px solid #f5c6cb; /* Kırmızı kenarlık */
    border-radius: 5px; /* Kenarları yuvarlaştır */
    color: #721c24; /* Koyu kırmızı metin rengi */
    font-size: 14px; /* Metin boyutu */
  }
  
  .error {
    border: 1px solid red;
  }
  
  .valid {
    border: 1px solid green;
  }
  
  .profile-card {
    background: var(--navbar-bg-color);
    /* Yarı saydam arka plan */
    backdrop-filter: blur(10px);
    /* Bulanık arka plan efekti */
    -webkit-backdrop-filter: blur(10px);
    /* Safari için destek */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    /* Hafif bir gölge */
  }`, "",{"version":3,"sources":["webpack://./src/pages/ProfilePage/ProfilePage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,6BAA6B;IAC/C,aAAa,EAAE,yCAAyC;IACxD,yBAAyB,EAAE,2BAA2B;IACtD,yBAAyB,EAAE,qBAAqB;IAChD,kBAAkB,EAAE,0BAA0B;IAC9C,cAAc,EAAE,6BAA6B;IAC7C,eAAe,EAAE,iBAAiB;EACpC;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,kCAAkC;IAClC,0BAA0B;IAC1B,2BAA2B;IAC3B,6BAA6B;IAC7B,mCAAmC;IACnC,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yCAAyC;IACzC,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,oBAAoB;EACtB","sourcesContent":[".error-messages {\r\n    margin-top: 10px; /* Üstte biraz boşluk bırak */\r\n    padding: 10px; /* Mesajların etrafında biraz iç boşluk */\r\n    background-color: #f3bbc0; /* Açık kırmızı arka plan */\r\n    border: 1px solid #f5c6cb; /* Kırmızı kenarlık */\r\n    border-radius: 5px; /* Kenarları yuvarlaştır */\r\n    color: #721c24; /* Koyu kırmızı metin rengi */\r\n    font-size: 14px; /* Metin boyutu */\r\n  }\r\n  \r\n  .error {\r\n    border: 1px solid red;\r\n  }\r\n  \r\n  .valid {\r\n    border: 1px solid green;\r\n  }\r\n  \r\n  .profile-card {\r\n    background: var(--navbar-bg-color);\r\n    /* Yarı saydam arka plan */\r\n    backdrop-filter: blur(10px);\r\n    /* Bulanık arka plan efekti */\r\n    -webkit-backdrop-filter: blur(10px);\r\n    /* Safari için destek */\r\n    border-radius: 10px;\r\n    padding: 20px;\r\n    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);\r\n    align-items: center;\r\n    justify-content: center;\r\n    display: flex;\r\n    flex-direction: column;\r\n    /* Hafif bir gölge */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
