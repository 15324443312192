// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datetime-picker-container {
  margin-top: 15px;
  border-radius: 10px;
  color: var(--text-color);
  font-family: "Montserrat", sans-serif;
  flex-direction: row;
  display: flex;
  gap: 2%;
}

.input-group {
  margin-bottom: 15px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  color: var(--title-color);
}

.datetime-input {
  appearance: none;
  width: 100%;
  padding: 10px;
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
  background-color: var(--card-bg-color);
  color: var(--text-color);
  font-family: "Montserrat", sans-serif;
}

.submit-button {
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: var(--button-hover-bg-color);
}`, "",{"version":3,"sources":["webpack://./src/components/DateTimePicker/DateTimePicker.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,wBAAwB;EACxB,qCAAqC;EACrC,mBAAmB;EACnB,aAAa;EACb,OAAO;AACT;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EAEE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,2CAA2C;EAC3C,kBAAkB;EAClB,sCAAsC;EACtC,wBAAwB;EACxB,qCAAqC;AACvC;;AAEA;EACE,wCAAwC;EACxC,+BAA+B;EAC/B,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,8CAA8C;AAChD","sourcesContent":[".datetime-picker-container {\r\n  margin-top: 15px;\r\n  border-radius: 10px;\r\n  color: var(--text-color);\r\n  font-family: \"Montserrat\", sans-serif;\r\n  flex-direction: row;\r\n  display: flex;\r\n  gap: 2%;\r\n}\r\n\r\n.input-group {\r\n  margin-bottom: 15px;\r\n  width: 100%;\r\n}\r\n\r\nlabel {\r\n  display: block;\r\n  margin-bottom: 5px;\r\n  color: var(--title-color);\r\n}\r\n\r\n.datetime-input {\r\n  -webkit-appearance: none;\r\n  appearance: none;\r\n  width: 100%;\r\n  padding: 10px;\r\n  border: 1px solid var(--input-border-color);\r\n  border-radius: 5px;\r\n  background-color: var(--card-bg-color);\r\n  color: var(--text-color);\r\n  font-family: \"Montserrat\", sans-serif;\r\n}\r\n\r\n.submit-button {\r\n  background-color: var(--button-bg-color);\r\n  color: var(--button-text-color);\r\n  padding: 10px 20px;\r\n  border: none;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n}\r\n\r\n.submit-button:hover {\r\n  background-color: var(--button-hover-bg-color);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
