import React from 'react';
import './DateTimePicker.css'

const DateTimePicker = ({ selectedDate, setSelectedDate, selectedTime, setSelectedTime }) => {

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const handleTimeChange = (e) => {
        setSelectedTime(e.target.value);
    };

    return (
        <div className="datetime-picker-container">
            <div className="input-group">
                <input
                    type="date"
                    id="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    className="datetime-input"
                />
            </div>
            <div className="input-group">
                <input
                    type="time"
                    id="time"
                    value={selectedTime}
                    onChange={handleTimeChange}
                    className="datetime-input"
                />
            </div>
        </div>
    );
};

export default DateTimePicker;
