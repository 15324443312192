import React from "react";
import "./MultipleMessageBox.css";
import { useLanguage } from "../../LanguageProvider";

const MultipleMessageBox = ({
  show,
  onClose,
  actions = [],
  title,
  message,
}) => {
  const { getTranslation } = useLanguage();

  if (!show) {
    return null;
  }

  return (
    <div className="multipleMessageBox-overlay">
      <div className="multipleMessageBox">
        <h2 className="multipleMessageBox-title">{title}</h2>
        <p className="multipleMessageBox-message">{message}</p>
        <div className="multipleMessageBox-buttons">
          {actions.map((action, index) => (
            <button
              key={index}
              className={`multipleMessageBox-button ${action.type}`}
              onClick={() => {
                if (action.callback) action.callback();
              }}
            >
              {action.title}
            </button>
          ))}
          <button
            className="multipleMessageBox-button cancel"
            onClick={onClose}
          >
            {getTranslation("close")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MultipleMessageBox;
