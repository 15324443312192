// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DateRangeSelector.css */

.date-range-selector {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 20px 0;
}

.date-range-button {
  padding: 10px 20px;
  border: 2px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.date-range-button.selected {
  border-color: rgba(45, 194, 141, 1) !important;
  background-color: rgba(45, 194, 141, 0.1) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.date-range-button:hover {
  background-color: #f1f1f1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Kronos/Views/DateRangeSelector.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B;EACE,aAAa;EACb,SAAS;EACT,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,wCAAwC;EACxC,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,8CAA8C;EAC9C,oDAAoD;EACpD,mDAAmD;AACrD;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* DateRangeSelector.css */\r\n\r\n.date-range-selector {\r\n  display: flex;\r\n  gap: 10px;\r\n  justify-content: center;\r\n  margin: 20px 0;\r\n}\r\n\r\n.date-range-button {\r\n  padding: 10px 20px;\r\n  border: 2px solid #ddd;\r\n  border-radius: 8px;\r\n  background-color: #f9f9f9;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n  cursor: pointer;\r\n  transition: all 0.3s ease;\r\n}\r\n\r\n.date-range-button.selected {\r\n  border-color: rgba(45, 194, 141, 1) !important;\r\n  background-color: rgba(45, 194, 141, 0.1) !important;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;\r\n}\r\n\r\n.date-range-button:hover {\r\n  background-color: #f1f1f1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
