// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-input {
  width: 30px;
  padding: 5px;
  border: 1px solid white;
  text-align: center;
  background-color: transparent;
  color: white;
}

.time-input.editing {
  border: 1px solid #007bff;
  background-color: #2b2f38;
  color: #a5a5a5;
}

.time-input:focus {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Kronos/Views/TimeInput.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".time-input {\r\n  width: 30px;\r\n  padding: 5px;\r\n  border: 1px solid white;\r\n  text-align: center;\r\n  background-color: transparent;\r\n  color: white;\r\n}\r\n\r\n.time-input.editing {\r\n  border: 1px solid #007bff;\r\n  background-color: #2b2f38;\r\n  color: #a5a5a5;\r\n}\r\n\r\n.time-input:focus {\r\n  outline: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
