import React, { useState } from "react";
import "./DateRangeSelector.css";

const DateRangeSelector = ({ onSelectRange }) => {
  const [selectedRange, setSelectedRange] = useState("7days");

  const handleSelect = (range) => {
    setSelectedRange(range);
    onSelectRange(range);
  };

  return (
    <div className="date-range-selector">
      <button
        className={`tab ${selectedRange === "7days" ? "selected" : ""}`}
        onClick={() => handleSelect("7days")}
      >
        7 Gün
      </button>
      <button
        className={`tab ${selectedRange === "30days" ? "selected" : ""}`}
        onClick={() => handleSelect("30days")}
      >
        30 Gün
      </button>
      <button
        className={`tab ${selectedRange === "365days" ? "selected" : ""}`}
        onClick={() => handleSelect("365days")}
      >
        365 Gün
      </button>
      <button
        className={`tab ${selectedRange === "custom" ? "selected" : ""}`}
        onClick={() => handleSelect("custom")}
      >
        İki Tarih Arası
      </button>
    </div>
  );
};

export default DateRangeSelector;
